import { Autocomplete, TextField } from '@mui/material'
import CameraCard from 'components/CameraPreview/CameraCard'
import { CustomInput } from 'components/Events/Fields'
import { Select } from 'components/Reports/Fields'
import ReportCard from 'components/Reports/ReportCard'
import ReportFilters from 'components/Reports/ReportFilters'
import LargeCard from 'components/Structure/LargeCard'
import HeaderText from 'components/Texts/HeaderText'
import SubheaderText from 'components/Texts/SubheaderText'
import { useLoading } from 'hooks/useLoading'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setPrevCamera } from 'redux/cameraSlice'
import useAPI from 'services/ApiService'
import { CMContext } from '../Cameras/CameraLayout'
import { buildReportLink, readReportParamsFromQuery, ReportTypes } from './Reports'


const formatCameraState = (id, name) => `${id}:${name}`
const NewReportForm = () => {
    const { startDate, endDate } = useSelector((state) => state.dateRange)
    const history = useHistory()
    const { searchFilter, setSearchFilter, cameras, filteredCameras } = useContext(CMContext)

    // Read report parameters from the URL
    const [params, updateParams] = useState(() => readReportParamsFromQuery())
    const dispatch = useDispatch()
    const { prevCamera } = useSelector(state => state.camera)
    // Form validation state
    const [validated, setValidated] = useState(false)

    // Required selected values
    const [selectedReportType, selectReportType] = useState(params.reportType ?? "")
    const [selectedCamera, selectCamera] = useState(null)
    const [reportSelected, setReportSelected] = useState(false)
    const [cameraInfo, setCameraInfo] = useState(null)

    /**
     * Build the URL for the report.
     *
     * @return {string} The relative URL to redirect the user to.
     */
    const buildURL = () => {
        // The system id and the camera id are bundled together
        const [systemID, cameraID] = selectedCamera?.split(":")

        return buildReportLink(selectedReportType?.path, {
            systemID,
            cameraID,
            carriageways: params.carriageways,
            lanes: params.lanes,
            vehicles: params.vehicles,
            range: { start: startDate, end: endDate }
        })
    }

    /** Build the URL based on the user's selection. */
    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setValidated(true)
        setSearchFilter('')
        const url = buildURL()
        if (url) {
            history.push(url)
        }
    }
    const handleSelectReport = () => {
        setReportSelected(false)
        selectReportType('')
        setSearchFilter('')
    }
    const handleSearchCamera = (e) => {
        if (e) {
            setSearchFilter(e.target.value)
        }
    }

    /**
     * 
     */
    const handleSetCameraDetails = (camera) => {
        selectCamera(formatCameraState(camera?.sys_id, camera?.camera_id ?? camera?.name))
        setCameraInfo(camera)
    }

    const handleSelect = (e) => {
        if (e) {
            let cam = e.target.textContent
            setSearchFilter(cam)
            // console.log(cam)
            const camera = cameras.find(camera => camera.name === cam)
            handleSetCameraDetails(camera)
        }
    }

    

    useEffect(() => {
        if (prevCamera) {
            setSearchFilter(prevCamera?.name ?? '')
            handleSetCameraDetails(prevCamera)
        }
    }, [prevCamera])

    useEffect(() => {
        if (searchFilter?.length === 0) {
            selectCamera(null)
            setCameraInfo(null)

        }
    }, [searchFilter])

    // console.log({ selectedCamera, selectedReportType, searchFilter, prevCamera, cameraInfo })
    return (
        <div className=''>
            <div className='rp-header'>
                <HeaderText title='Generate report' />
            </div>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {!reportSelected && <div className='mt-4'>
                    <SubheaderText title='Select report type' />
                    <div className='rp-cont'>
                        {ReportTypes?.map((item, i) =>
                            <ReportCard
                                selectedReportType={selectedReportType}
                                reportSelected={reportSelected}
                                setReportSelected={setReportSelected}
                                setCameraInfo={() => handleSetCameraDetails(prevCamera)}
                                selectReportType={selectReportType}
                                report={item} key={i} />)}
                    </div>
                </div>}
                {reportSelected && <div className='col-xl-6 offset-xl-3'>
                    <LargeCard title="Generate a report">
                        {/* Select: Camera */}
                        <div onClick={handleSelectReport} className='report-text'>
                            {selectedReportType?.name}
                        </div>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            placeholder='Search Cameras'
                            inputValue={searchFilter}
                            onInputChange={handleSearchCamera}
                            options={filteredCameras}
                            getOptionLabel={option => option.name}
                            onChange={handleSelect}
                            sx={{ width: '100%', height: 40, marginBottom: 5, outlineColor: '#f0f0f0' }}
                            renderInput={(params) => <TextField value={searchFilter} onChange={handleSearchCamera} {...params} label={'Search Cameras'} />}
                        />
                        <div className='mb-3'>
                            {cameraInfo && <CameraCard key={cameraInfo.uuid} camera={cameraInfo} />}
                        </div>


                        {/* Select: Carriageways,  */}
                        {selectedCamera && (
                            <ReportFilters
                                isReportForm={true}
                                report={selectedReportType}
                                camera={cameraInfo}
                                updateParams={updateParams}
                                selectedCamera={selectedCamera}
                            />
                        )}

                        <Button variant="primary" type="submit" className="float-right">Generate report</Button>
                    </LargeCard>
                </div>}
            </Form>
        </div>
    )
}

export default NewReportForm
