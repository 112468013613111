import { LoadingWrapper } from "components";
import { useLoading, useQueryLoading } from "hooks/useLoading";
import React from "react";
import { useSelector } from "react-redux";
import useAPI from "services/ApiService";
import TrafficFlowDiagram from "./TrafficDiagram";
import CountDiagram from "./CountDiagram";
import useCameraManagementAPI from "services/CameraManagementService";
import CountDiagram2 from "./CountDiagram2";

const TrafficDiagramWrapper = ({ camera }) => {
  // console.log(camera)
  const api = useAPI();
  const configApi = useCameraManagementAPI()
  const [systemID, cameraID, cameraUUID] = [camera?.sys_id, camera?.camera_id, camera?.uuid];
  const dateRange = useSelector((state: ReduxStore) => state.dateRange);
  const [report, reportLoadingState] = useQueryLoading(
    ['reportODMatrix', systemID, cameraID, dateRange],
    () => api.getOriginDestinationReport(systemID, cameraID, dateRange), 
    [systemID, cameraID, dateRange]
  );
  const [cameraLines, loadingCameraLines] = useQueryLoading(
    ['cameraLines', cameraUUID],
    () => configApi.getCameraLines(cameraUUID),
    [cameraUUID]
  );

  const ghentUUIDs = [
    '5e9d46bf-38a3-40bf-9014-1222cbf1e948',
    'b221f81a-aa21-4462-907c-8167b90266f8',
    '37edbc37-1eb1-4e2b-885c-5709db77a729'
  ];
  
  const isGhent = ghentUUIDs.includes(cameraUUID);
  // console.log(reportLoadingState,loadingCameraLines)

  // console.log(report);
  return (
    <LoadingWrapper state={reportLoadingState}>
      {/* <TrafficFlowDiagram data={report} /> */}
      {isGhent ? <CountDiagram2 data={report} positions={cameraLines} /> : <CountDiagram data={report} positions={cameraLines} /> }

    </LoadingWrapper>
  );
};

export default TrafficDiagramWrapper;
