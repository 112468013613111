import { CameraPreview, LoadingWrapper } from 'components'
import React, { useContext } from 'react'
import { CMContext } from './CameraLayout'
import { Link } from 'react-router-dom'
import { AddLocation } from "@material-ui/icons"

const AllCameras = () => {
    const { searchFilter, cameras } = useContext(CMContext)
    const filteredCameras = [...cameras].filter((item) => searchFilter.length > 0 ? item.name?.includes(searchFilter) : item)
    console.log(filteredCameras)
    return (
        <div className="row" >
            {filteredCameras.length > 0 ? filteredCameras?.map((camera: CameraDTO) => (
                <CameraPreview key={camera.uuid} camera={camera} />
            )) : (
              <div className='row m-0' style={{justifyContent: 'center', gap: '8px', width: '100%', alignItems: 'center'}} >
                <p className="text-center m-0" >No camera added yet</p>
                <Link to="/admin/cm/add" className="btn btn-primary" >
                  <AddLocation /> Add a new camera
                </Link>
              </div>
            )}
        </div>
    )
}

export default AllCameras
