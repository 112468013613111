import cameraConfig from "../CameraImage/CameraConfig";
import { getVehicleTypeColor, getVehicleTypes } from "../../config/VehicleTypes";
import { get } from "../../utils/data";
import { defaultCameraImage, getCameraImageURL } from "../../utils/camera";
import { useEffect, useRef, useState } from "react";
import { buildWorker } from "../../utils/workers";
import { TrajectoriesWorker } from "./Worker";
import { useRefresh } from "../../hooks/useRefresh";
import VehicleFilter from "./VehicleFIlter";
import { LoadingImage } from "../LoadingImage/LoadingImage";
import { BsToggleOff } from "react-icons/bs";
import { BsToggleOn } from "react-icons/bs";
import { humanDate } from "utils/time";
import { isDateRangeMoreThan30Days } from "utils/functions";
import toast from "react-hot-toast";
import Heatmap from "./Heatmap";
/**
 *
 * @param {CameraDTO} camera
 * @param {VehicleTrajectoryDTO} vehicles
 * @param {PedestrianTrajectoryDTO} pedestrians
 * @return {JSX.Element}
 * @constructor
 */
const Presentation = ({ camera, vehicles, pedestrians, isLast24, setisLast24, dateRange, localRange }) => {
  const canvas = useRef();
  // The image to use
  const image = get(camera, ["images", 0], defaultCameraImage);

  // The state that will force the refresh of the canvas
  const [refresh, forceRefresh] = useRefresh();

  // The background workers
  const [worker, setWorker] = useState();
  const [offscreenCanvas: OffscreenCanvas, setOffscreenCanvas] = useState();
  const [isHeatmapMode, setIsHeatmapMode] = useState(false);

  // Show entry and exit points or not
  const [showEntryPoints, setShowEntryPoints] = useState(true);
  const [showExitPoints, setShowExitPoints] = useState(false);

  // Custom filter for the vehicle type
  const [vehicleTypeCount, setVehicleTypeCount] = useState();
  const [filterVehicleType, setFilterVehicleType] = useState(getVehicleTypes(true).reduce((acc, type) => {
    acc[type.type] = true;
    return acc;
  }, []));

  // Filter for vehicle types
  const vehicleFilter = VehicleFilter(filterVehicleType, setFilterVehicleType);

  // Redraw the lines
  useEffect(() => {
    const trajectories = {};

    // Handle: Vehicles
    if (vehicles) {
      for (const entry of vehicles.entries) {
        for (const exit of entry.exits) {
          for (const vehicle of exit.vehicles) {

            // Make sure we initialize the color
            const color = getVehicleTypeColor(vehicle.vehicle_type);
            trajectories[vehicle.vehicle_type] || (trajectories[vehicle.vehicle_type] = { type: vehicle.vehicle_type, color: color, lines: [] });

            // Put for each trajectory
            for (const trajectory of vehicle.trajectories) {
              trajectories[vehicle.vehicle_type].lines.push(trajectory.trajectory);
            }
          }
        }
      }
    }

    // Handle: Pedestrians
    if (pedestrians) {
      trajectories["pedestrian"] = {
        type: "pedestrian",
        color: getVehicleTypeColor("pedestrian"),
        lines: pedestrians.entries.map(entry => entry.trajectory)
      };
    }

    // Extract data from the trajectories
    const lines = {};
    const count = {};
    for (const type of Object.values(trajectories)) {

      // Always count type
      count[type.type] = type.lines.length;

      // Only draw lines for enabled types
      if (filterVehicleType[type.type]) {
        lines[type.color] = type.lines;
      }
    }

    // Set the count
    setVehicleTypeCount(count);
    // console.log({ count, lines, trajectories })

    // Send a request to worker
    worker?.postMessage({
      msg: "DRAW", payload: {
        lines: lines,
        entryPoints: showEntryPoints,
        exitPoints: showExitPoints,
      }
    });
  }, [refresh, worker, vehicles, pedestrians, showEntryPoints, showExitPoints, filterVehicleType]);

  // Initialize the canvas
  useEffect(() => {

    // Initialize only once
    if (canvas.current && camera && !offscreenCanvas) {

      // Load the canvas
      const offscreenCanvas = canvas.current.transferControlToOffscreen();
      setOffscreenCanvas(offscreenCanvas);

      // Define the worker and its handler
      const instance = buildWorker(TrajectoriesWorker);
      instance.onmessage = (message) => {
        if (message) {
          switch (message.data.msg) {
            case "READY":
              forceRefresh();
              break;

            case "DONE":
              break;

            case "ERROR":
              console.error(message.data.payload);
              break;

            case "ECHO":
              // console.table({ echo: message.data.payload });
              break;

            default:
              console.warn("Unknown message", message.data);
          }
        }
      };
      setWorker(instance);

      const message = {
        msg: "INIT",
        payload: {
          canvas: offscreenCanvas
        }
      };

      instance.postMessage(message, [offscreenCanvas]);
    }
  }, [camera, canvas, offscreenCanvas, forceRefresh]);



  const handleToggle = () => {
    const invalidDate = isDateRangeMoreThan30Days(dateRange)

    if (invalidDate) {
      toast('Trajectory data is limited to a maximum of 30 days. Please change the date range to 30 days or less and try again.', {
        duration: 10000
      })
      return
    }
    setisLast24(false)
  }

  // It is much easier to show/hide canvas then to reinitialize it everytime in the LoadingWrapper
  return (
    <div className="row" style={{ display: vehicles || pedestrians ? "flex" : "none" }}>
      <div className="col-md-9">
        <LoadingImage className="absolute img-hd" src={getCameraImageURL(camera?.uuid)} alt={camera?.location} />
        <canvas ref={canvas} width={image.width} height={image.height} className="absolute full-width" />
       
      </div>

      <div className="col-md-3">

        {/* Show entry and/or exit points */}
        <div className="legend card">
          {
            [["entry", showEntryPoints, setShowEntryPoints], ["exit", showExitPoints, setShowExitPoints]].map(point => (
              <label key={point[0]} className="item">
                <input type="checkbox" checked={point[1]} onChange={(event) => point[2](event.target.checked)} />
                <em className="circle" style={{ background: cameraConfig.point.type[point[0]].active.fill, color: "red" }} />
                <span>Show {point[0]} points</span>
              </label>
            ))
          }
        </div>

        {/* Show vehicle types */}
        <div className="legend card">

          {
            getVehicleTypes(true).map(type => (
              <label key={type.type} className="item" onDoubleClick={() => vehicleFilter.one(type.type)}>
                <input type="checkbox" name="VehicleType" checked={filterVehicleType[type.type]} onChange={(event) => vehicleFilter.set(type.type, event.target.checked)} />
                <em style={{ background: type.color }} />
                <span>{type.plural} {vehicleTypeCount ? ` (${vehicleTypeCount[type.type] ?? 0})` : null}</span>
              </label>
            ))
          }

          <div className="select-controls">
            <span onClick={() => vehicleFilter.all(true)}>select all</span>
            &nbsp;
            /
            &nbsp;
            <span onClick={() => vehicleFilter.all(false)}>none</span>
          </div>
        </div>
       
        {!localRange?.isLessthan24 && <div>
          {isLast24 ?
            <div onClick={handleToggle}><BsToggleOn className="toggle active" />
              <span>Show only data for the last 24hrs of the chosen date range. </span>
            </div> :
            <div onClick={() => setisLast24(true)}>  <BsToggleOff className="toggle" />
              <span>Show only data for the last 24hrs of the chosen date range.</span>
            </div>
          }
        </div>}
      </div>
    </div>
  );
};

export default Presentation;
