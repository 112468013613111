import { useLoading } from "../../../../../hooks/useLoading"
import useAPI from "../../../../../services/ApiService"
import Presentation from "../../../../../components/Trajectories/Presentation"
import { LoadingWrapper } from "../../../../../components"
import { useDispatch, useSelector } from "react-redux"
import useCameraManagementAPI from "../../../../../services/CameraManagementService"
import HeatmapDemo from "components/Trajectories/OldPresentation"
import "./Dashboard.scss"
import { useEffect, useState } from "react"
import { loadLast24Hrs, sameDate } from "utils/functions"
import toast from "react-hot-toast"
import { updateDateRange } from "redux/dateRangeSlice"
import { useQueryLoading } from "../../../../../hooks/useLoading"

/**
 * Show the trajectories on the camera details page.
 *
 * @param {CameraDTO} camera
 *
 * @return {JSX.Element}
 * @constructor
 */
const Trajectories = ({ camera }) => {
  const [systemID, cameraID] = [camera?.sys_id, camera?.camera_id]

  // Handle date changes
  const dateChange = useSelector((state: ReduxStore) => state.dateRange)
  const [isLast24, setisLast24] = useState(true) 
  const [localRange, setLocalRange] = useState(loadLast24Hrs(dateChange, isLast24))
  const dispatch = useDispatch()
  // const dateRange = useStoreDateRange()
  const api = useAPI()

  // console.log({ localRange, isLast24, dateChange })
  // Audit log
  useCameraManagementAPI().log("> Camera trajectories", {
    url: window.location.pathname,
    params: { subpage: "trajectories", systemID, cameraID }
  })


  // Load the data
  const [vehicles: VehicleTrajectoryDTO, vehiclesLoadingState] = useQueryLoading(
    ["vehicles", systemID, cameraID, localRange],
    () => api.getVehicleTrajectory(systemID, cameraID, localRange),
    [systemID, cameraID, localRange],
  )
  const [pedestrians: PedestrianTrajectoryDTO, pedestriansLoadingState] = useQueryLoading(
    ["pedestrians", systemID, cameraID, localRange],
    () => api.getPedestrianTrajectory(systemID, cameraID, localRange),
    [systemID, cameraID, localRange],
  )


  const state = [vehiclesLoadingState, pedestriansLoadingState];

  useEffect(() => {
    const check = sameDate(localRange.globalRange, dateChange)

    if (check) {
      setLocalRange(loadLast24Hrs(dateChange, isLast24))
    }
    else {
      setisLast24(true)
      setLocalRange(loadLast24Hrs(dateChange, true))
    }
    return () => {
      toast.dismiss()
    }
  }, [dateChange, isLast24])

  console.log(vehicles, pedestrians)

  if(vehicles?.entries.length === 0 && pedestrians?.entries.length === 0) return null;

  return (
  <>
    <LoadingWrapper state={state}>
      <Presentation
        camera={camera}
        isLast24={isLast24}
        setisLast24={setisLast24}
        vehicles={vehicles}
        pedestrians={pedestrians}
        localRange={localRange}
        dateRange={dateChange} />
    </LoadingWrapper>
  </>
  )
}

export default Trajectories
