/* eslint-disable react-hooks/exhaustive-deps */
// TrafficFlowDiagram.js
import React, { useEffect, useRef, useState, useMemo } from "react";
import * as d3 from "d3";
import { getVehicleTypes } from "config/VehicleTypes";

const CountDiagram = ({ data, positions }) => {
  // NEW CODE: State to manage selected vehicle types
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState(getVehicleTypes(false).map((type) => type.type));

  const vehicleTypeCounts = useMemo(() => {
    if (!data || !Array.isArray(data.entries)) return;
    return calculateVehicleTypeCounts(data);
  }, [data]);

  // console.log(positions, data);
  const svgContainerRef = useRef(null);
  useEffect(() => {
    if (!data || !Array.isArray(data.entries) || !positions) return;
    // console.log("working");
    d3.select(svgContainerRef.current)
      .selectAll("*")
      .remove();

    // Set up SVG dimensions
    const svgWidth = 1200;
    const svgHeight = 1200;

    // Extract lanes from config
    const lanesByPosition = extractLanes(positions);

    // User-defined sides (positions)
    const selectedSides = filterKeysWithNonEmptyArrays(lanesByPosition);

    // Define all possible sides with their properties
    const allSides = [
      { name: "North", position: "N", color: "red" },
      { name: "East", position: "E", color: "orange" },
      { name: "South", position: "S", color: "blue" },
      { name: "West", position: "W", color: "green" }
    ];

    // Filter sides based on selectedSides
    const sides = allSides.filter((side) => selectedSides.includes(side.position));

    const test = processTrafficData(data, lanesByPosition, indexMapping, selectedVehicleTypes);

    // Function to determine segment color based on position, lane, and segment
    function getSegmentColor(position, lane) {
      const colorMap = {
        N: { 1: "green", 2: "red" },
        E: { 1: "green", 2: "red" },
        S: { 1: "red", 2: "green" },
        W: { 1: "red", 2: "green" }
      };

      if (colorMap[position] && colorMap[position][lane]) {
        return colorMap[position][lane];
      }

      return "gray"; // default color
    }

    // Number of lanes and segments
    const lanesPerSide = 2;
    const segmentsPerLane = 3;

    // Generate segment data with lanes
    const segmentData2 = sides
      .flatMap((side) =>
        Array.from({ length: lanesPerSide }, (_, laneIndex) =>
          Array.from({ length: segmentsPerLane }, (_, segmentIndex) => ({
            side: side.name,
            position: side.position,
            lane: laneIndex + 1, // Lane 1 or 2
            segment: segmentIndex + 1, // Segment 1, 2, 3
            color: getSegmentColor(side.position, laneIndex + 1)
          }))
        )
      )
      .flat();

    // Filter traffic flows based on selected sides, lanes, and segments
    const trafficData = test.filter(
      (d) =>
        allSides.some((side) => side.position === d.source.position) &&
        allSides.some((side) => side.position === d.target.position) &&
        d.source.laneNumber <= lanesPerSide &&
        d.target.laneNumber <= lanesPerSide &&
        d.source.segment <= segmentsPerLane &&
        d.target.segment <= segmentsPerLane
    );

    // console.log(trafficData);

    // Create SVG container
    const svg = d3
      .select(svgContainerRef.current)
      .append("svg")
      .attr("width", "100%") // Make SVG responsive
      .attr("height", "100%") // Make SVG responsive
      .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`) // Use viewBox to maintain aspect ratio
      .style("background", "white");
    // .style("border", "1px solid black");

    // Scales for positions
    const segmentLength = 100;
    const segmentSize = segmentLength / segmentsPerLane;
    const margin = 300;

    // Create scales for line widths
    const maxLineThickness = segmentSize; // Maximum line width shouldn't exceed segment size
    const trafficValues = trafficData.map((d) => d.value);
    const lineWidthScale = d3
      .scaleLinear()
      .domain([1, d3.max(trafficValues)])
      .range([1, maxLineThickness]);

    // Helper function to get segment coordinates based on position, lane, and segment
    function getSegmentCoords2(position, lane, segment) {
      const idx = segment - 1;
      const laneOffset = lane === 1 ? -50 : 50; // Adjust lane separation as needed
      const totalLength = segmentSize * segmentsPerLane;

      switch (position) {
        case "N":
          return {
            x: (svgWidth - totalLength) / 2 + idx * segmentSize + laneOffset,
            y: margin,
            width: segmentSize,
            height: 10 // Adjust for better visibility
          };
        case "S":
          return {
            x: (svgWidth - totalLength) / 2 + idx * segmentSize + laneOffset,
            y: svgHeight - margin - 10,
            width: segmentSize,
            height: 10
          };
        case "W":
          return {
            x: margin,
            y: (svgHeight - totalLength) / 2 + idx * segmentSize + laneOffset,
            width: 10,
            height: segmentSize
          };
        case "E":
          return {
            x: svgWidth - margin - 10,
            y: (svgHeight - totalLength) / 2 + idx * segmentSize + laneOffset,
            width: 10,
            height: segmentSize
          };
        default:
          return {};
      }
    }

    // Helper function to get color by position
    function getColorByPosition(position) {
      const side = sides.find((s) => s.position === position);
      return side ? side.color : "black";
    }

    trafficData.forEach((connection) => {
      const sourceCoords = getSegmentCoords2(connection.source.position, connection.source.laneNumber, connection.source.segment);
      const targetCoords = getSegmentCoords2(connection.target.position, connection.target.laneNumber, connection.target.segment);

      const sourceX =
        connection.source.position === "E" || connection.source.position === "W"
          ? sourceCoords.x + sourceCoords.width / 2
          : sourceCoords.x + sourceCoords.width / 2;
      const sourceY =
        connection.source.position === "N" || connection.source.position === "S"
          ? sourceCoords.y + sourceCoords.height / 2
          : sourceCoords.y + sourceCoords.height / 2;

      const targetX =
        connection.target.position === "E" || connection.target.position === "W"
          ? targetCoords.x + targetCoords.width / 2
          : targetCoords.x + targetCoords.width / 2;
      const targetY =
        connection.target.position === "N" || connection.target.position === "S"
          ? targetCoords.y + targetCoords.height / 2
          : targetCoords.y + targetCoords.height / 2;

      const lineWidth = lineWidthScale(connection.value);

      // Determine if the line should be straight or curved
      const isStraightLine =
        (connection.source.position === "N" && connection.target.position === "S") ||
        (connection.source.position === "S" && connection.target.position === "N") ||
        (connection.source.position === "E" && connection.target.position === "W") ||
        (connection.source.position === "W" && connection.target.position === "E") ||
        connection.source.position === connection.target.position;

      const lineColor = getColorByPosition(connection.source.position);

      if (isSpecialConnection(connection)) {
        // Draw special curved connections
        const dx = targetX - sourceX;
        const dy = targetY - sourceY;
        const dr = Math.sqrt(dx * dx + dy * dy) * 0.8; // Adjust curvature

        svg
          .append("path")
          .attr("d", `M${sourceX},${sourceY} A${dr},${dr} 1 0,0 ${targetX},${targetY}`)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("fill", "none")
          .attr("stroke-opacity", 0.4);
      } else if (isStraightLine) {
        // Draw straight lines for direct connections
        svg
          .append("line")
          .attr("x1", sourceX)
          .attr("y1", sourceY)
          .attr("x2", targetX)
          .attr("y2", targetY)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("stroke-opacity", 0.4);
      } else {
        // Draw default curved lines
        const dx = targetX - sourceX;
        const dy = targetY - sourceY;
        const dr = Math.sqrt(dx * dx + dy * dy); // Default curvature

        svg
          .append("path")
          .attr("d", `M${sourceX},${sourceY} A${dr},${dr} 0 0,1 ${targetX},${targetY}`)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("fill", "none")
          .attr("stroke-opacity", 0.4);
      }
    });

    // Draw segments as rectangles
    svg
      .selectAll(".segment")
      .data(segmentData2)
      .enter()
      .append("rect")
      .attr("class", "segment")
      .attr("x", (d) => getSegmentCoords2(d.position, d.lane, d.segment).x)
      .attr("y", (d) => getSegmentCoords2(d.position, d.lane, d.segment).y)
      .attr("width", (d) => getSegmentCoords2(d.position, d.lane, d.segment).width)
      .attr("height", (d) => getSegmentCoords2(d.position, d.lane, d.segment).height)
      .attr("fill", (d) => d.color);

    // Calculate total traffic per segment
    const trafficPerSegment = {};

    trafficData.forEach((d) => {
      const sourceKey = `${d.source.position}-${d.source.laneNumber}-${d.source.segment}`;
      const targetKey = `${d.target.position}-${d.target.laneNumber}-${d.target.segment}`;

      // Accumulate "Out" traffic for source segment
      if (!trafficPerSegment[sourceKey]) {
        trafficPerSegment[sourceKey] = { in: 0, out: 0 };
      }
      trafficPerSegment[sourceKey].out += d.value;

      // Accumulate "In" traffic for target segment
      if (!trafficPerSegment[targetKey]) {
        trafficPerSegment[targetKey] = { in: 0, out: 0 };
      }
      trafficPerSegment[targetKey].in += d.value;
    });

    // Aggregate "In" and "Out" traffic per direction
    const trafficPerDirection = {
      N: { in: 0, out: 0 },
      E: { in: 0, out: 0 },
      S: { in: 0, out: 0 },
      W: { in: 0, out: 0 }
    };

    segmentData2.forEach((segment) => {
      const key = `${segment.position}-${segment.lane}-${segment.segment}`;
      if (trafficPerSegment[key]) {
        const { in: inTraffic, out: outTraffic } = trafficPerSegment[key];
        trafficPerDirection[segment.position].in += inTraffic;
        trafficPerDirection[segment.position].out += outTraffic;
      }
    });

    // Add Traffic Count Labels to Each Segment
    svg
      .selectAll(".segment-label")
      .data(segmentData2)
      .enter()
      .append("text")
      .attr("class", "segment-label")
      .attr("text-anchor", (d) => {
        return d.position === "E" ? "start" : d.position === "W" || d.position === "N" || d.position === "S" ? "end" : "middle";
      })
      .attr("dominant-baseline", "middle")
      .style("font-size", "12px")
      .style("fill", "black")
      .text((d) => {
        // const key = `${d.position}-${d.lane}-${d.segment}`;
        // return trafficPerSegment[key] && trafficPerSegment[key].in + trafficPerSegment[key].out
        //   ? trafficPerSegment[key].in + trafficPerSegment[key].out
        //   : "";
        const key = `${d.position}-${d.lane}-${d.segment}`;
        const data = trafficPerSegment[key];

        if (data && data.in != null && data.out != null) {
          const sum = data.in + data.out;
          return sum.toLocaleString();
        } else {
          return "";
        }
      })
      .each(function(d) {
        const coords = getSegmentCoords2(d.position, d.lane, d.segment);
        const x = coords.x + coords.width / 2 + (d.position === "E" ? 15 : d.position === "W" ? -15 : 0);
        const y = coords.y + coords.height / 2 + (d.position === "N" ? -15 : d.position === "S" ? 15 : 0);

        d3.select(this)
          .attr("x", x)
          .attr("y", y)
          .attr("transform", d.position === "N" ? `rotate(90, ${x}, ${y})` : d.position === "S" ? `rotate(-90, ${x}, ${y})` : null);
      });

    // Add Lane Labels
    allSides.forEach((side) => {
      Object.entries(lanesByPosition[side.position]).forEach(([_, lane]) => {
        // Determine label position based on side and lane
        // console.log(lane);
        let labelX, labelY, anchor, rotation;

        const distanceLabels = lane.laneNumber === 1 ? -50 : 50;

        switch (side.position) {
          case "N":
            labelX = (svgWidth - segmentSize * segmentsPerLane) / 2 + (segmentsPerLane / 2) * segmentSize + distanceLabels;
            labelY = margin - 170; // Above the lanes
            anchor = "middle";
            rotation = 90;
            break;
          case "S":
            labelX = (svgWidth - segmentSize * segmentsPerLane) / 2 + (segmentsPerLane / 2) * segmentSize + distanceLabels;
            labelY = svgHeight - margin + 170; // Below the lanes
            anchor = "middle";
            rotation = 90;
            break;
          case "W":
            labelX = margin - 170; // To the left of the lanes
            labelY = (svgHeight - segmentSize * segmentsPerLane) / 2 + (segmentsPerLane / 2) * segmentSize + distanceLabels;
            anchor = "middle";
            rotation = 0;
            break;
          case "E":
            labelX = svgWidth - margin + 170; // To the right of the lanes
            labelY = (svgHeight - segmentSize * segmentsPerLane) / 2 + (segmentsPerLane / 2) * segmentSize + distanceLabels;
            anchor = "middle";
            rotation = 0;
            break;
          default:
            break;
        }

        svg
          .append("text")
          .attr("class", "labels")
          .attr("x", labelX)
          .attr("y", labelY)
          .attr("text-anchor", anchor)
          .attr("dominant-baseline", "middle")
          .attr("transform", `rotate(${rotation}, ${labelX}, ${labelY})`)
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .selectAll("tspan")
          .data(splitText(lane.name)) // Split the text into two lines
          .enter()
          .append("tspan")
          .attr("x", labelX) // Keep the same x position for all lines
          .attr("dy", (d, i) => (i === 0 ? "0em" : "1.2em")) // Adjust vertical spacing
          .text((d) => d);
      });
    });
  }, [data, selectedVehicleTypes]);

  const hasPositions = hasPosition(positions);

  // console.log(hasPositions);
  if (!data || !Array.isArray(data.entries) || !hasPositions.hasEntryPosition || !hasPositions.hasExitPosition) {
    return <div style={{ textAlign: "center" }}></div>;
  }

  if(!positions){
    return <div style={{ textAlign: "center" }}>No position configured</div>;
  }

  const handleVehicleTypeChange = (type) => {
    setSelectedVehicleTypes((prevTypes) => (prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]));
  };

  const handleSelectAll = () => {
    setSelectedVehicleTypes(getVehicleTypes(true).map((type) => type.type));
  };

  const handleSelectNone = () => {
    setSelectedVehicleTypes([]);
  };

  const handleVehicleTypeDoubleClick = (type) => {
    setSelectedVehicleTypes([type]);
  };

  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center", width: "100%", margin: "0 auto" }}>
      <div id="chart" ref={svgContainerRef} style={{ flexGrow: 1 }}></div>

      <div className="col-md-3">
        <div className="legend card">
          {getVehicleTypes(false).map((type) => (
            <label key={type.type} className="item" style={{ marginRight: "10px" }} onDoubleClick={() => handleVehicleTypeDoubleClick(type.type)}>
              <input
                type="checkbox"
                name="VehicleType"
                checked={selectedVehicleTypes.includes(type.type)}
                onChange={() => handleVehicleTypeChange(type.type)}
              />
              {/* <em style={{ background: type.color }} /> */}
              <span>
                {type.plural} ({vehicleTypeCounts[type?.type] ?? 0 })
              </span>
            </label>
          ))}

          <div className="select-controls">
            <span onClick={handleSelectAll}>select all</span>
            &nbsp; / &nbsp;
            <span onClick={handleSelectNone}>none</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDiagram;

function processTrafficData(trafficData, lanePositionData, indexMapping, selectedVehicleTypes) {
  // Create a mapping from (lane UUID + type) to lane information
  const laneMap = {};

  for (const [position, lanes] of Object.entries(lanePositionData)) {
    for (const lane of lanes) {
      const key = `${lane.uuid}_${lane.type}`;
      laneMap[key] = {
        position,
        laneNumber: lane.laneNumber
      };
    }
  }

  const result = [];

  for (const entry of trafficData.entries) {
    const sourceKey = `${entry.transit_point.lane.uuid}_${entry.transit_point.type}`;
    const sourceLaneInfo = laneMap[sourceKey];

    // Only process if source is an ENTRY and exists in laneMap
    if (entry.transit_point.type !== "ENTRY" || !sourceLaneInfo) continue;

    for (const flow of entry.flows) {
      const targetKey = `${flow.transit_point.lane.uuid}_${flow.transit_point.type}`;
      const targetLaneInfo = laneMap[targetKey];

      // Only process if target is an EXIT and exists in laneMap
      if (flow.transit_point.type !== "EXIT" || !targetLaneInfo) continue;

      // NEW CODE: Filter vehicles based on selectedVehicleTypes
      const filteredVehicles = flow.vehicles.filter((vehicle) => selectedVehicleTypes.includes(vehicle.vehicle_type));

      // console.log(filteredVehicles);

      const totalVehicleCount = filteredVehicles.reduce((sum, vehicle) => sum + vehicle.count, 0);

      if (totalVehicleCount === 0) continue; // Skip if no vehicles of selected types

      // console.log(totalVehicleCount)

      // Construct the key for index mapping
      const mappingKey = `${sourceLaneInfo.position}${sourceLaneInfo.laneNumber}-${targetLaneInfo.position}${targetLaneInfo.laneNumber}`;
      const indexMap = indexMapping[mappingKey] || {
        sourceIndex: 1,
        targetIndex: 1
      };

      result.push({
        source: {
          position: sourceLaneInfo.position,
          laneNumber: sourceLaneInfo.laneNumber,
          segment: indexMap.sourceIndex
        },
        target: {
          position: targetLaneInfo.position,
          laneNumber: targetLaneInfo.laneNumber,
          segment: indexMap.targetIndex
        },
        value: totalVehicleCount
      });
    }
  }

  return result;
}

function filterKeysWithNonEmptyArrays(data) {
  return Object.keys(data).filter((key) => Array.isArray(data[key]) && data[key].length > 0);
}

// Function to check if a connection is among the specified ones
function isSpecialConnection(connection) {
  const specialConnections = [
    {
      source: { position: "S", laneNumber: 2, segment: 1 },
      target: { position: "W", laneNumber: 1, segment: 3 }
    },
    {
      source: { position: "E", laneNumber: 1, segment: 3 },
      target: { position: "S", laneNumber: 1, segment: 3 }
    },
    {
      source: { position: "N", laneNumber: 1, segment: 3 },
      target: { position: "E", laneNumber: 2, segment: 1 }
    },
    {
      source: { position: "W", laneNumber: 2, segment: 1 },
      target: { position: "N", laneNumber: 2, segment: 1 }
    }
  ];

  return specialConnections.some(
    (special) =>
      special.source.position === connection.source.position &&
      special.source.laneNumber === connection.source.laneNumber &&
      special.source.segment === connection.source.segment &&
      special.target.position === connection.target.position &&
      special.target.laneNumber === connection.target.laneNumber &&
      special.target.segment === connection.target.segment
  );
}

// Direction to Position Mapping
const directionMap = {
  UP: "N",
  RIGHT: "E",
  DOWN: "S",
  LEFT: "W"
};

// Function to extract lanes from config and map to positions
function extractLanes(config) {
  const lanesByPosition = {
    N: [],
    E: [],
    S: [],
    W: []
  };

  // Combine entries and exits for processing
  const allTransitPoints = [...config.entries, ...config.exits];

  allTransitPoints.forEach((tp) => {
    // Map the direction string to its corresponding cardinal direction
    const position = directionMap[tp.position];
    if (position) {
      // Check if the lane with the same UUID already exists to avoid duplicates
      const existingLane = lanesByPosition[position].find((lane) => lane.uuid === tp.lane.uuid);

      if (!existingLane) {
        const laneNumber = getLaneNumber(tp.type, tp.position); // Assign lane numbers 1 and 2
        // Determine lane type based on position and lane number
        let laneType;
        if (position === "N" || position === "E") {
          laneType = laneNumber === 1 ? "ENTRY" : "EXIT";
        } else if (position === "S" || position === "W") {
          laneType = laneNumber === 1 ? "EXIT" : "ENTRY";
        }

        // Push the lane information into the respective position array
        lanesByPosition[position].push({
          laneNumber: laneNumber,
          name: tp.lane.name,
          uuid: tp.lane.uuid, // Preserve the lane UUID
          type: laneType // ENTRY or EXIT based on position and laneNumber
        });
      }
    }
  });

  return lanesByPosition;
}

const getLaneNumber = (type, direction) => {
  const laneMap = {
    ENTRY: {
      UP: 1,
      LEFT: 2,
      RIGHT: 1,
      DOWN: 2
    },
    EXIT: {
      UP: 2,
      LEFT: 1,
      RIGHT: 2,
      DOWN: 1
    }
  };
  return laneMap[type]?.[direction];
};

// Get index mapping based on sides present
const indexMapping = {
  "N1-S1": { sourceIndex: 2, targetIndex: 2 },
  "S2-N2": { sourceIndex: 2, targetIndex: 2 },
  "W2-E2": { sourceIndex: 2, targetIndex: 2 },
  "E1-W1": { sourceIndex: 2, targetIndex: 2 },
  "N1-W1": { sourceIndex: 1, targetIndex: 1 },
  "W2-N2": { sourceIndex: 1, targetIndex: 1 },
  "N1-E2": { sourceIndex: 3, targetIndex: 1 },
  "E1-N2": { sourceIndex: 1, targetIndex: 3 },
  "S2-W1": { sourceIndex: 1, targetIndex: 3 },
  "W2-S1": { sourceIndex: 3, targetIndex: 1 },
  "S2-E2": { sourceIndex: 3, targetIndex: 3 },
  "E1-S1": { sourceIndex: 3, targetIndex: 3 }
};

function splitText(text) {
  const words = text.split(" ");
  const midpoint = Math.ceil(words.length / 2);
  const line1 = words.slice(0, midpoint).join(" ");
  const line2 = words.slice(midpoint).join(" ");
  return [line1, line2];
}

function hasPosition(data) {
  if (!data) return;
  const hasEntryPosition = data.entries && data.entries.length > 0 ? data.entries.some((entry) => entry.position !== null) : false;

  const hasExitPosition = data.exits && data.exits.length > 0 ? data.exits.some((exit) => exit.position !== null) : false;

  return {
    hasEntryPosition,
    hasExitPosition
  };
}

// Function to calculate vehicle type counts based only on entries
function calculateVehicleTypeCounts(data) {
  const vehicleTypeCounts = {};

  // Iterate over entries only
  for (const entry of data.entries) {
    // Sum counts from vehicles under each entry's flows
    for (const flow of entry.flows) {
      for (const vehicle of flow.vehicles) {
        const type = vehicle.vehicle_type;
        const count = vehicle.count;
        if (!vehicleTypeCounts[type]) {
          vehicleTypeCounts[type] = 0;
        }
        vehicleTypeCounts[type] += count / 2;
      }
    }
  }

  return vehicleTypeCounts;
}
