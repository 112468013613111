import useCameraManagementAPI from "../../../../../services/CameraManagementService"
import useAPI from "../../../../../services/ApiService"
import { useLoading } from "../../../../../hooks/useLoading"
import { LoadingWrapper } from "../../../../../components"
import { useSelector } from "react-redux"
import AllCharts from "./AllCharts"
import { useRef, useState } from "react"
import { useQueryLoading } from "../../../../../hooks/useLoading"

/** The default height of the chart. */
const height = 350

/**
 * Show the various charts and graphs for this camera.
 *
 * @param {CameraDTO} camera
 *
 * @return {JSX.Element}
 * @constructor
 */
export const Stats = ({ camera, reportType }) => {
  const [systemID, cameraID, cameraName] = [camera?.sys_id, camera?.camera_id, camera?.name]
  const dateRange = useSelector((state) => state.dateRange)
  const api = useAPI()
  const laneSection = useRef()
  const carriagewaySection = useRef()
  const totalSection = useRef()
  

  const metricsType = [{
    key: 'lane',
    value: 'By lane',
    ref: laneSection
  }, {
    key: 'carriageway',
    value: 'By Carriageway',
    ref: carriagewaySection
  }, {
    key: 'total',
    value: 'Totals',
    ref: totalSection
  },]

  const [metrics, setMetrics] = useState(metricsType[0])

  // Audit log
  useCameraManagementAPI().log("> Camera stats", {
    url: window.location.pathname,
    params: { subpage: "stats", systemID, cameraID }
  })

  // Load the data
  const [data: GranularDataWithChartsDTO, dataLoadingState] = useQueryLoading(
    ["granularData", systemID, cameraID, dateRange],
    () => api.getDataGranularSummary({ systemID, cameraID }),
    [systemID, cameraID, dateRange],
  ) 

  const [fdoLane, fdoLoadingLaneState] = useQueryLoading(
    ["fdoLane", systemID, cameraID, dateRange],
    () => api.getFDSLaneMetrics(systemID, cameraID, dateRange),
    [systemID, cameraID, dateRange],
  ) 


  const [fdoCarriage, fdoLoadingCarriageState] = useQueryLoading(
    ["fdoCarriage", systemID, cameraID, dateRange],
    () => api.getFDSCarriageWayMetrics(systemID, cameraID, dateRange),
    [systemID, cameraID, dateRange],
  ) 

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };
  // console.log({ data, fdoCarriage, fdoLane, metrics })

  if (data?.data.length === 0 && fdoCarriage?.data.length === 0 && fdoLane?.data.length === 0) return null;

  return (
    <LoadingWrapper state={[dataLoadingState, fdoLoadingCarriageState]}>
      <div className=" dashboard-header">
        {metricsType.map(item =>
          <div key={item.key} onClick={() => {
            setMetrics(item)
            scrollDown(item.ref)
          }} className={`dashboard-header-menu ${item.key === metrics.key && 'active'}`}>{item.value}</div>
        )}
      </div>
      <AllCharts
        fdoCarriage={fdoCarriage}
        data={data} fdoLane={fdoLane}
        laneSection={laneSection}
        carriagewaySection={carriagewaySection}
        totalSection={totalSection}
        height={height}
        cameraName={cameraName}
      />
    </LoadingWrapper>
  )
}
