import { decodeBase64URL } from "../utils/data"
import { getTimezoneName, getTimezoneOffset, timestamp } from "../utils/time"

/**
 * The service responsible for the authentication of the user.
 */
class AuthService {
  constructor() {
    this.url = process.env.REACT_APP_API_AUTH_URL ?? "https://flow.greenroads.ai/user"
  }

  /**
   * Execute the API call.
   *
   * @param {string} url The URL to send the request to.
   * @param {string} method The method that send the request with.
   * @param {string} path The relative URL path to send the request to.
   * @param {?{}} data The body to send with the request.
   ** @param {string} token The token to send with the request headers.

   * @return {Promise<any>} The response from the server.
   */
  async _execute(method: string, url: string, path: string, data: null, token: null) {
    let body = undefined

    if (data != null) {
      body = JSON.stringify(data)
    }
    const config = {
      method,
      body
    }
    if (token) {
      config.headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "Client-Timezone": getTimezoneName(),
        "Client-Timezone-Offset": getTimezoneOffset()
      }
    } else if (data !== null) {
      config.headers = {
        "Content-Type": "application/json",
        "Client-Timezone": getTimezoneName(),
        "Client-Timezone-Offset": getTimezoneOffset()
      }
    }

    // Execute the query
    const res = await fetch(`${url}${path}`, config)

    // Guard: The response must be a positive one
    if (!res.ok) {
      throw new Error("An error has occurred")
    }

    // Make sure there is a response body
    const responseBody = await res.text()
    if (responseBody === "") {
      return {}
    }

    return JSON.parse(responseBody)
  }

  /**
   * Try to log the user in.
   *
   * @param {string} username The username.
   * @param {string} password The password.
   *
   * @return {Promise<KeyCloakResponse>} The response from the server.
   */
  async login(username: string, password: string) {
    return this._execute(
      "POST",
      this.url,
      "/auth/login",
      {
        email_address: username,
        password
      },
      null
    )
  }

  /**
   * Try to refresh the jwt with the refresh token.
   *
   * @param {string} refreshToken The refresh token to use.
   *
   * @return {Promise<KeyCloakResponse>} The response from the server.
   */
  async refresh(refreshToken: string) {
    const email = this.readFromStorage()?.auth?.user?.email
    if (!email) {
      return null
    }
    return this._execute("POST", this.url, "/auth/refresh", {
      email_address: email,
      refresh_token: refreshToken
    })
  }

  /**
   * Get the authentication data stored in the persistent storage.
   *
   * @return {?AuthStoreDTO} The authentication stored in the persistent storage.
   */
  readFromStorage() {
    try {
      const auth = localStorage.getItem("auth")
      if (auth) {
        return JSON.parse(auth)
      }
    } catch (e) {
    }

    return undefined
  }

  /**
   * Write the authentication data to persistent storage.
   *
   * @param {?AuthStoreDTO} auth The authentication to write, or null to clear.
   */
  writeToStorage(auth) {
    try {
      auth != null ? localStorage.setItem("auth", JSON.stringify(auth)) : localStorage.removeItem("auth")
    } catch (e) {
    }
  }

  /**
   * Get the payload from the JWT.
   *
   * @param {string} jwt The original JWT as received from the auth server.
   *
   * @return {?KeyCloakJWT} The parsed payload.
   */
  decodeJWT(jwt: string) {
    if (jwt != null) {
      try {
        return JSON.parse(decodeBase64URL(jwt.split(".")[1]))
      } catch (e) {
        console.error(e)
      }
    }

    return null
  }

  /**
   * Check if the supplied token is still valid, or expired.
   *
   * @param {TokenDTO} token The token to check for validity.
   * @param {number} buffer The buffer to take remove from the token expire time, just to be sure.
   *
   * @return {boolean} True if the token is not expired, false otherwise.
   */
  isValid = (token: TokenDTO, buffer: number = 900): boolean => {
    return !!(token && token?.token && token?.expires > timestamp() + buffer)
  }

  /**
   * Edit a user.
   *
   * @param {?{}} data The new user details.
   * @param {string} id The user id.
   *
   * @return {string} User has been edited if success.
   */
  async editUser(data, id) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("PUT", this.url, `/admin/realms/${this.realm}/users/${id}`, data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /*
   **
   **
   ** NEW API**

   */
  /**
   * Get my profile.
   *
   * @return {object} User details.
   */
  async getMyProfile() {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("GET", this.url, "/me", null, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Edit my profile.
   *
   * @param {?{}} data The user details : firstName & lastName.
   *
   * @return {object} User details.
   */
  async editMyProfile(data) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("PATCH", this.url, "/me", data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Change my password.
   *
   * @param {?{}} data The new user password.
   *
   * @return {string} Pasword has been changed if succuess.
   */
  async changeMyPassword(data) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("POST", this.url, "/me/change-password", data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Forgot my password.
   *
   * @param {?{}} data The new user password.
   *
   * @return {string} Nothing.
   */
  async forgotMyPassword(data) {
    try {
      return this._execute("POST", this.url, "/auth/reset-password", data)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Tenant users.
   *
   * @param {?{}} tenantId Tenant id.
   *
   * @return {string} List of users.
   */
  async getTenantUsers(tenantId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("GET", this.url, `/management/${tenantId}/users`, null, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Create a user.
   *
   * @param {?{}} data new user data.
   * @param {string} sysId id of tenant the user belongs to.
   *
   * @return {string} Nothing.
   */
  async createUser(data, sysId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("POST", this.url, `/management/${sysId}/users/`, data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Update a user.
   *
   * @param {?{}} data new user data.
   * @param {string} sysId id of tenant the user belongs to.
   * @param {string} userId id of the user.
   *
   * @return {string} Nothing.
   */
  async updateUser(data, sysId, userId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("PATCH", this.url, `/management/${sysId}/users/${userId}`, data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Add user to a tenant.
   *
   * @param {?{}} data new user data.
   * @param {string} sysId id of tenant the user belongs to.
   *
   * @return {string} Nothing.
   */
  async addUserToTenant(data, sysId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("PUT", this.url, `/management/${sysId}/users/`, data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Remove user to a tenant.
   *
   * @param {string} sysId id of tenant the user belongs to.
   * @param {string} userId id of the user.
   *
   * @return {string} Nothing.
   */
  async removeUserFromTenant(sysId, userId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("DELETE", this.url, `/management/${sysId}/users/${userId}`, null, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all tenants.
   *
   * @return {string} tenants.
   */
  async getTenants() {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("GET", this.url, "/tenants", null, token)
    } catch (err) {
      return []
    }
  }

  /**
   * Create tenant.
   *
   * @param {string} data new tenant data.
   * @return {string} tenant data.
   */
  async createTenant(data) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("POST", this.url, "/tenants", data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Edit tenant.
   * @param {string} sysId id of tenant.
   * @param {string} data new tenant data.
   * @return {string} tenant data.
   */
  async editTenant(data, sysId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("PATCH", this.url, `/tenants/${sysId}`, data, token)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Delete tenant.
   * @param {string} sysId id of tenant.

   * @return {string} tenant data.
   */
  async deleteTenant(sysId) {
    const token = this.readFromStorage()?.auth?.token
    try {
      return this._execute("DELETE", this.url, `/tenants/${sysId}`, null, token)
    } catch (err) {
      console.error(err)
    }
  }
}

export default new AuthService()
