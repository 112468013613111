import "./LocationsPage.scss"
import { allowEmpty, extractTrueState } from "../../../components/LoadingWrapper/LoadingWrapper"
import useAPI from "../../../services/ApiService"
import { State, useLoading } from "../../../hooks/useLoading"
import { LoadingWrapper, Map } from "../../../components"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { updateDateRange } from "redux/dateRangeSlice"
import { useEffect, useContext } from "react"
import { useDispatch } from "react-redux"
import { addDays, validateDate } from "utils/time"
import { CMContext } from '../Cameras/CameraLayout'

export const LocationsPage = () => {
  const { cameras: cameraData } = useContext(CMContext)
  const dispatch = useDispatch()
  // Load the cameras from the Data API
  const api = useAPI()

  let [cameras: CameraDTO[], camerasLoadingState] = useLoading(() => api.cameras())
  // cameras = cameras != null ? cameras : []

  // Load the cameras from the Configuration API
  // const configuration = useCameraManagementAPI()
  // const [configurationCameras: CMCameraResponse[], configurationCamerasLoadingState] = useLoading(() => configuration.cameras())

  // Define the date range for the overview
  const days = 30

  const dateRange: DateRangeDTO = {
    start: addDays(-days),
    end: addDays(0),
    prevRange: null
  }

  // useEffect(() => {
  //   dispatch(updateDateRange(dateRange))
  //   return () => {

  //   }
  // }, [dispatch])

  // Combine the cameras
  // if (State.READY === extractTrueState([allowEmpty(camerasLoadingState), configurationCamerasLoadingState])) {
  //   for (const confCamera of configurationCameras) {
  //     if (!cameras.find(camera => camera.uuid === confCamera.uuid)) {
  //       cameras.push({
  //         uuid: confCamera.uuid,
  //         name: confCamera.name,
  //         sys_id: confCamera.system,
  //         has_stream: confCamera.has_stream,
  //         group: confCamera.camera_group,
  //         location: confCamera.locality,
  //         lat: confCamera.map?.latitude,
  //         lon: confCamera.map?.longitude,
  //         image: confCamera.image?.url,
  //         status: { "congestion": null }
  //       })
  //     }
  //   }
  // }

  return (
    <LoadingWrapper state={[allowEmpty(camerasLoadingState)]}>
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <Map markers={cameraData ?? []} />
        </div>
      </div>
    </LoadingWrapper>
  )
}
