import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import "./BarChart.scss";
import { extend } from "../../utils/data";
import { humanNumber, humanPercent } from "../../utils/functions";

/**
 * Draw a horizontal bar chart.
 *
 * @param {string} title The title to show above the chart.
 * @param {KeyValue[]} data The key-value pair of data.
 * @param {boolean} percentage True to show percentages as well.
 * @param {{}} options The additional options for ApexCharts.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const BarChart = ({ title, data, percentage = false, options = {} }) => {

  // Create a reference to the DOM element that shows the chart
  const chartRef = useRef();

  useEffect(() => {

    // Make sure the DOM element has been initialized
    if (!chartRef.current) {
      return;
    }

    // Get the maximum value and the total count
    const maxValue = data.reduce((max, item) => Math.max(max, item.value), 0);
    const total = data.reduce((acc, perVehicleType) => acc + perVehicleType.value, 0);

    // Get the optimized label for the X axis
    const getLabelX = (val) => {

      // Always 0
      if (!val) {
        return 0;
      }

      // Format accordingly to the maximum value
      const steps = { "M": 1000000, "K": 1000 };
      for (const unit in steps) {
        if (steps.hasOwnProperty(unit) && Math.round(maxValue / steps[unit]) >= 1) {
          return (val / steps[unit]).toFixed(1).replace(/\.0$/, "") + unit;
        }
      }

      // If it doesn't qualify for M or K, and is a decimal number
      if (val % 1 !== 0) {
        return val.toFixed(1);
      }

      return val;
    };

    // Define the options for the chart
    const useOptions = extend(options, {
      chart: {
        type: "bar",
        height: 300,
        animations: {
          enabled: false
        }
      },
      series: [{
        name: "Count ",
        data: data.map(row => row.value)
      }],
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      tooltip: {
        enabled: false
      },
      dataLabels: {
        textAnchor: "start",
        formatter: val => humanNumber(val) + (percentage ? val ? ` (${humanPercent(100 * val / total)})` : " (0%)" : ""),
        style: {
          colors: ["#3f4254"]
        }
      },
      xaxis: {
        categories: data.map(row => row.key),
        labels: {
          formatter: getLabelX
        }
      },
      yaxis: {},
      legend: {
        show: null
      },

      colors: ["#95cc98", "#c9d591", "#c4e6b3", "#95d3e0", "#7ec5c9", "#7eb0c5"]
    });

    // Draw the chart
    const chart = new ApexCharts(chartRef.current, useOptions);
    chart.render();

    // Make sure chart is properly disposed of
    return () => chart.destroy();

  }, [data, percentage, options]);

  return (
    <div className="chart bar-chart">
      {title && <h5>{title}</h5>}
      <div ref={chartRef} />
    </div>
  );
};

export default BarChart;
