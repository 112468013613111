import { useEffect, useState } from "react"
import { MapContainer, TileLayer, Marker } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-markercluster"
import { MapModal } from "../Modal/MapModal"
import { blackMarker, greenMarker, orangeMarker, redMarker, yellowMarker } from "../MapMarkers/MapMarkers"
import "./Map.scss"
import { MessageModal, useMessageModal } from "../Modal/MessageModal"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { setDefaultLocation } from "redux/cameraSlice"

export const Map = ({ markers }) => {
  const messageModal = useMessageModal()
  const [selectedMarker: { systemID: string, cameraID: string }, setSelectedMarker] = useState()
  const [currentLocation, setCurrentLocation] = useState([35.881856, 14.443718])
  const [ready, setReady] = useState(false)
  const dispatch = useDispatch()
  // console.log({ selectedMarker, currentLocation })
  /**
   * Get the icon based on the supplied congestion.
   *
   * @param {CongestionDTO} congestion The current congestion at the location.
   *
   * @return {L.Icon} The icon to use.
   */
  const getIcon = (congestion) => {
    switch (congestion) {
      case "None":
      case "Light":
        return greenMarker

      case "Medium":
        return yellowMarker

      case "Heavy":
        return orangeMarker

      case "Standstill":
        return redMarker

      default:
        return blackMarker
    }
  }
  useEffect(() => {
    if(markers.length > 0){
    setCurrentLocation([markers[0].lat, markers[0].lon])
    dispatch(setDefaultLocation([markers[0].lat, markers[0].lon]))
    setReady(true)
    }else{
      setReady(true)
    }
  }, [markers])

  return (
    <>
      {ready && <MapContainer
        className="map-container"
        center={currentLocation}
        zoom={13}
        scrollWheelZoom={true}
        style={{ height: "780px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>
          {markers && markers.map((marker: CameraDTO) => {
            return (
              <Marker
                key={marker.uuid}
                position={[marker.lat, marker.lon]}
                icon={getIcon(marker.status.congestion)}
                eventHandlers={{
                  click: () => marker.camera_id
                    ? setSelectedMarker({ systemID: marker.sys_id, cameraID: marker.camera_id })
                    : messageModal.show(`Camera ${marker.name}`, `Camera "${marker.name}" currently does not have any data.`)
                }}
              />
            )
          })}
        </MarkerClusterGroup>
      </MapContainer>}

      { // Show the modal popup when a marker is selected
        selectedMarker && (
          <MapModal
            onHide={() => setSelectedMarker(null)}
            systemID={selectedMarker.systemID}
            cameraID={selectedMarker.cameraID}
          />
        )
      }

      <MessageModal hook={messageModal} />
    </>
  )
}
