import { useSelector } from "react-redux"
import "./Dashboard.scss"
import { State, useLoading, useQueryLoading } from "../../../../../hooks/useLoading"
import useCameraManagementAPI from "../../../../../services/CameraManagementService"
import VehicleTypeChart from "./Dashboard/VehicleTypeChart"
import Indicators from "./Dashboard/Indicators"
import BigCharts from "./Dashboard/BigCharts"
import Peak from "./Dashboard/Peak"
import Heatmap from "./Dashboard/Heatmap"
import FlowSelectedPoint from "./Dashboard/FlowSelectedPoint"
import SelectedPointHeader from "./Dashboard/SelectedPointHeader"
import FlowGlobal from "./Dashboard/FlowGlobal"
import LargeCard from "../../../../../components/Structure/LargeCard"
import useAPI from "../../../../../services/ApiService"
import { CameraImage, LoadingWrapper } from "../../../../../components"
import { useState } from "react"
import { useCameraImage } from "../../../../../hooks/useCameraImage"
import LiveStream from "app/pages/CameraManagement/LiveStream"
import { useQuery } from "react-query"
import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import { useMe } from "hooks/useMe"
import { extractTrueState } from "components/LoadingWrapper/LoadingWrapper"

/**
 * Show the dashboard of the camera.
 *
 * @param {CameraDTO} camera
 *
 * @return {JSX.Element}
 * @constructor
 */
export const Dashboard = ({ camera }) => {
  const [systemID, cameraID] = [camera?.sys_id, camera?.camera_id]
  const dateRange = useSelector((state: ReduxStore) => state.dateRange)
  const { isAdmin } = useMe()
  const api = useAPI()
  const cameraAPI = useCameraManagementAPI()

  // console.log(camera)

  // Audit log
  useCameraManagementAPI().log("> Camera dashboard", {
    url: window.location.pathname,
    params: { subpage: "overview", systemID, cameraID }
  })

  // Read the camera info from the redux
  const { width, height, location, name } = useSelector((state: ReduxStore) => state.camera)
  const cameraImage = useCameraImage(camera)

  // Do not render heat map if range is less than 3 hours
  const showHeatmap = (dateRange.end.getTime() - dateRange.start.getTime()) / 3600 >= 3

  /** Fetch: The key performance indicators for the current camera. */
  const [selectedKPI, setSelectedKPI] = useState()
  const [isADT, setisADT] = useState(false)
  const [isStreamOn, setIsStreamOn] = useState(false)
  const [activeStream, setActiveStream] = useState('hls')
  const [kpi: KPIArrayDTO[], kpiState] = useQueryLoading(["Kpi"],() => api.kpi({ systemID, cameraID }), [dateRange])
  const [videoUrl, videoLoadingState] = useQueryLoading(["videoUrl"],() => cameraAPI.startRtspStream(camera?.uuid), [])

  /** Fetch: Contains all the transit points: general info (position, name, etc...), count, average speed, with vehicle type breakdown. */
  const [transitSummary: TransitSummaryDTO, transitSummaryState] = useQueryLoading(
    ["transitSummary", systemID, cameraID, dateRange],
    () => api.getTransitSummary({ systemID, cameraID }),
    [systemID, cameraID, dateRange],
  ) 

  /** Fetch: Contains flow information between transit points, without vehicle type breakdown. */
  const [transitFlow: TransitFlowDTO, transitFlowState] = useQueryLoading(
    ["transitFlowData", systemID, cameraID, dateRange],
    () => api.getTransitFlow({ systemID, cameraID }),
    [systemID, cameraID, dateRange],
  ) 


  /** Fetch: Contains per-hour data: count and average speed, without vehicle type. Also contains prepared data for KPI charts. */
  const [granularData: GranularDataWithChartsDTO, granularDataState] = useQueryLoading(
    ["granularData", systemID, cameraID, dateRange],
    () => api.getDataGranularSummary({ systemID, cameraID }, isADT),
    [systemID, cameraID, dateRange, isADT],
  ) 

  /** The currently selected point and all the points related to it. */
  const [selectedPoint: TransitFlowPointDTO, setSelectedPoint] = useState()
  const [relatedPoints: LaneFlowDTO[], setRelatedPoints] = useState([])

  /** The carriageway to have its transit points highlighted. */
  const [selectedCarriagewayUUID, setSelectedCarriagewayUUID] = useState()
  const highlightedPoints = !selectedCarriagewayUUID
    ? []
    : transitSummary?.transit_points
      .filter((point) => point.transit_point.carriageway.uuid === selectedCarriagewayUUID)
      .map((point) => point.transit_point.uuid)

  /**
   * Set the active lane point.
   *
   * @param {TransitFlowPointDTO} point The point to activate, or null to clear the active point.
   */
  const activatePoint = (point: TransitFlowPointDTO) => {

    // Remove all highlighted points
    setSelectedCarriagewayUUID(null)

    // Clear
    if (point == null) {
      setSelectedPoint(null)
      setRelatedPoints([])
      return
    }

    // Update all states
    setSelectedPoint(point)

    // Get related points
    const result = transitFlow.entries.find((item) => item.transit_point.uuid === point.transit_point.uuid)
    setRelatedPoints(result.flows)
  }

  // The empty message to show if there is no data to show
  const emptyMessage = (
    <LargeCard title={name} subtitle={location}>
      There is no data to show for the date range selected.
    </LargeCard>
  )
  const streamData = [{
    type: 'hls',
    id: 1
  }, {
    type: 'media-chrome',
    id: 2
  },]

  if(!transitFlow?.entries && transitSummary?.transit_points.length === 0) return null;
  
  return (
    <LoadingWrapper
      state={[transitFlowState, transitSummaryState, kpiState, granularDataState]}
      onEmpty={emptyMessage}
    >
      <div className="row">

        {/* Camera image or KPI graphs */}
        <div className="col-xl-7 col-xxl-8 mt-4">
          {isAdmin && (camera.live  || camera.was_live) && <>
            {!isStreamOn ?
              <div onClick={() => setIsStreamOn(true)}><BsToggleOff className="toggle" />
                <span>Show live stream </span>
              </div> :
              <div className="stream-menu">
                <div onClick={() => setIsStreamOn(false)}>  <BsToggleOn className="toggle active" />
                  <span>turn off live stream</span>
                </div>
                {/* <div className="stream-tabs">
                  {streamData.map(item =>
                    <button onClick={() => setActiveStream(item.type)} key={item.id}
                      className="btn btn-primary stream-tab-btn">Stream {item.id}
                    </button>)}
                </div> */}
              </div>
            }
          </>}
          {!selectedKPI && !isStreamOn && (
            <CameraImage
              image={cameraImage}
              height={height}
              width={width}
              points={transitFlow?.entries}
              selectedPoint={selectedPoint}
              setSelectedPoint={(point) => activatePoint(point)}
              relatedPoints={relatedPoints}
              highlightedPoints={highlightedPoints}
            />
          )}
          {!selectedKPI && isStreamOn && (
            <LiveStream hls_url={videoUrl?.hls_url} uuid={camera?.uuid} activeStream={activeStream} />
          )}

          {/* Show the selected KPI as a chart */}
          <BigCharts selected={selectedKPI} data={granularData} label={kpi?.filter(item => item.key === selectedKPI)} />
        </div>

        <div className="col-xl-5 col-xxl-4">

          {/* The key indicators */}
          <Indicators
            indicators={kpi}
            selected={selectedKPI}
            setSelected={(selected) => {
              activatePoint(null)
              selected === 'adt' ? setisADT(true) : setisADT(false)
              setSelectedKPI(selected)
            }} />

          {/* The vehicle type breakdown bar chart */}
          {!selectedKPI && <VehicleTypeChart transitSummary={transitSummary} selectedPoint={selectedPoint} />}

        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-4">
          <>
            {/* The header that shows the currently selected point, if any */}
            <SelectedPointHeader selectedPoint={selectedPoint} clearSelection={() => activatePoint(null)} />

            <div className="content-container">

              {/* Carriageway usage stats */}
              {transitSummary && <FlowGlobal
                selectedPoint={selectedPoint}
                transitSummary={transitSummary}
                selectedCarriagewayState={[selectedCarriagewayUUID, setSelectedCarriagewayUUID]}
              />}

              {/* The traffic flow between lanes */}
              <FlowSelectedPoint selectedPoint={selectedPoint} relatedPoints={relatedPoints} />

              {/* The information about the peak data */}
              {granularData && transitSummary && <Peak transitSummary={transitSummary} selectedPoint={selectedPoint} granularData={granularData} />}

              {/* The heat map of the traffic */}
              {showHeatmap && <Heatmap systemID={systemID} cameraID={cameraID} selectedPoint={selectedPoint} />}
            </div>
          </>
        </div>
      </div>
    </LoadingWrapper>
  )
}
